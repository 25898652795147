@import '@/styles/variables.scss';











































































































































































































































































































































.auth-v2 .v-input {
  &:not(.error--text) {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input__control .v-text-field__details {
      display: none;
    }
  }
  &.error--text {
    *.country-code {
      .v-input__control .v-text-field__details {
        display: block;
      }
    }
  }
}
.vue-tel-input-vuetify {
  align-items: flex-start;
}
.vue-tel-input-vuetify .v-input--has-state {
  transform: translateY(0px);
}
